import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const location = useLocation();

  const isMainPage = location.pathname === "/";
  const isPhotographyPage = location.pathname === "/photography";
  const isGalleryPage = location.pathname.startsWith("/photography") && location.pathname !== "/photography";

  return (
    <div className="Navbar">
      <header className="App-header">
        <div className="navbar">
        {!isMainPage && (
            <Link to="/" className="main-link">Back to Main</Link>
        )}
        {isGalleryPage && (
          <>
            <Link to="/photography" className="nav-link">Back to Gallery</Link>
          </>
        )}
        {isPhotographyPage && (
          <>
            <Link to="/prints" className="nav-link">Prints</Link>
          </>
        )}
        {isMainPage && (
          <>
            <a 
              href="https://github.com/Captainlearyo" 
              className="nav-link">
              GitHub
            </a>
              <Link to="/photography" className="nav-link">Photography</Link>
              <Link to="/resume" className="nav-link">My Info</Link>
              {/* <Link to="/contact" className="nav-link">Contact</Link> */}
            </>
          )}
        </div>
      </header>
    </div>
  );
}

export default Navbar;

import React from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Navbar from '../Navbar/Navbar';


import Resume from '../Resume/Resume';
import Contact from '../Contact/Contact';
import Photography from '../Photography/Photography';
import Prints from '../Photography/Prints/Prints';
import Kennen from '../Photography/Gallery/Kennen/Kennen';
import Main from '../Main/Main'; 

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Photography" element={<Photography />} />
          <Route path="/Photography/Prints" element={<Prints />} />
          <Route path="/Photography/Kennen" element={<Kennen />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;


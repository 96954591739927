import React from 'react';
import './Prints.css';
import Navbar from '../../Navbar/Navbar';



function Prints() {
  return (
    <Navbar />

);
}



export default Prints;